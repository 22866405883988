import React, { useState, useEffect, useCallback, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Edit, Globe, Image, Check, Rocket, Home, RefreshCw, Mic, Library, Wand2, RotateCcw, Music, AlertTriangle, X, ArrowRight, PanelLeftClose } from 'lucide-react';
import config from '../config';
import axios from 'axios';
import VideoPlayer from './VideoPlayer';
import BackgroundsTab from './BackgroundsTab';
import VoicesTab from './VoicesTab';
import TranscriptTab from './TranscriptTab';
import { useNavigate } from 'react-router-dom';
import PublishButton from './PublishButton';
import OverlayVideoPlayer from './OverlayVideoPlayer';
import MusicTab from './MusicTab';
import useTawkTo from '../hooks/useTawkTo';

const imageCache = new Map();

const ProcessingStatus = ({ user, uploadedVideoId, initialProjectTitle, initialTranscript, resetState, openSidePanel }) => {
  const [transcript, setTranscript] = useState(initialTranscript);
  const [backgroundImageId, setBackgroundImageId] = useState('monty');
  const [selectedTab, setSelectedTab] = useState('transcript');
  const [selectedLanguage, setSelectedLanguage] = useState(0);
  const [isTranslating, setIsTranslating] = useState(false);
  const [selectedVoice, setSelectedVoice] = useState("en-US-Journey-O");
  const [languages, setLanguages] = useState([]);
  const [voices, setVoices] = useState([]);
  const [images, setImages] = useState([]);
  const [dubbedVideoId, setDubbedVideoId] = useState(null);
  const [processingStatus, setProcessingStatus] = useState('Processing...');
  const [projectTitle, setProjectTitle] = useState(initialProjectTitle);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isConfigChanged, setIsConfigChanged] = useState(false);
  const lastProcessedConfig = useRef(null);
  const [publishingStatus, setPublishingStatus] = useState('idle');
  const navigate = useNavigate(); 
  const [imagesLoading, setImagesLoading] = useState(true);
  const [originalTranscript, setOriginalTranscript] = useState(initialTranscript);
  const [musicList, setMusicList] = useState([]);
  const [selectedMusicId, setSelectedMusicId] = useState(null);
  const [musicVolume, setMusicVolume] = useState(0);
  const [showWarning, setShowWarning] = useState(false);
  const [showLibraryPopup, setShowLibraryPopup] = useState(false);
  const [error, setError] = useState(null);

  // Add this near the top of the component
  useTawkTo();

  useEffect(() => {
    fetchLanguages();
    fetchVoices();
    fetchImages();
    fetchMusicList();
    handleVideoDub();
  }, []);

  const handleVoicePlay = async (voiceId, callback) => {
    try {
      const idToken = await user.getIdToken();
      const response = await axios.get(`${config.API_BASE_URL}/stream-voice/${voiceId}`, {
        headers: {
          'Authorization': `Bearer ${idToken}`,
          'Content-Type': 'audio/wav'
        },
        responseType: 'blob'
      });
      const url = URL.createObjectURL(response.data);
      const audio = new Audio(url);
      audio.play();
      if (callback) callback(audio);
    } catch (error) {
      setError('Error playing voice. Please try again.');
    }
  };
  
  const fetchLanguages = async () => {
    try {
      const idToken = await user.getIdToken();
      const response = await fetch(`${config.API_BASE_URL}/languages`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`
        }
      });
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.json();
      setLanguages(data.languages);
    } catch (error) {
      setError('Error fetching languages. Please try again.');
    }
  };

  const fetchVoices = async () => {
    try {
      const idToken = await user.getIdToken();
      const response = await fetch(`${config.API_BASE_URL}/voices`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`
        }
      });
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.json();
      setVoices(data.voices);
    } catch (error) {
      setError('Error fetching voices. Please try again.');
    }
  };

  const fetchImages = useCallback(async () => {
    setImagesLoading(true);
    try {
      const idToken = await user.getIdToken();
      const response = await fetch(`${config.API_BASE_URL}/images`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`
        }
      });
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.json();
      const fetchedImages = data.images || [];
      
      fetchedImages.forEach(imageId => {
        if (!imageCache.has(imageId)) {
          imageCache.set(imageId, null);
        }
      });

      setImages(fetchedImages);
      
      await Promise.all(fetchedImages.map(fetchImageData));
    } catch (error) {
      setError('Error fetching images. Please try again.');
      setImages([]);
    } finally {
      setImagesLoading(false);
    }
  }, [user]);

  const fetchImageData = async (imageId) => {
    if (imageCache.get(imageId) !== null) return;

    try {
      const idToken = await user.getIdToken();
      const response = await axios.get(`${config.API_BASE_URL}/image/${imageId}`, {
        headers: {
          'Authorization': `Bearer ${idToken}`,
        },
        responseType: 'blob'
      });
      const url = URL.createObjectURL(response.data);
      imageCache.set(imageId, url);
    } catch (error) {
      setError(`Error fetching image data for ID ${imageId}. Please try again.`);
      imageCache.set(imageId, null);
    }
  };

  const fetchMusicList = async () => {
    try {
      const idToken = await user.getIdToken();
      const response = await axios.get(`${config.API_BASE_URL}/background-music`, {
        headers: {
          'Authorization': `Bearer ${idToken}`
        }
      });
      setMusicList(response.data.music);
    } catch (error) {
      setError('Error fetching music list. Please try again.');
    }
  };

  const handleVideoDub = async () => {
    try {      
      const idToken = await user.getIdToken();
      const response = await axios.post(`${config.API_BASE_URL}/video-dub-async`, {
        video_id: uploadedVideoId,
        modifier: {
          language: { id: parseInt(selectedLanguage) },
          voice_id: selectedVoice,
          transcript: transcript.map((paragraph) => ({
            start: paragraph.start,
            end: paragraph.end,
            text: paragraph.text.trim(),
          })),          
        },
        title: projectTitle,
        useClonedVoice: false,
      }, {
        headers: { 'Authorization': `Bearer ${idToken}` }
      });
      setDubbedVideoId(response.data.video_id);
      checkProcessingStatus(response.data.video_id);
    } catch (error) {
      setError('Error dubbing video. Please try again.');
    }
  };

  const checkProcessingStatus = async (videoId) => {
    try {
      const idToken = await user.getIdToken();
      const response = await axios.get(`${config.API_BASE_URL}/is-processed/${videoId}`, {
        headers: { 'Authorization': `Bearer ${idToken}` }
      });
      if (response.data.is_processed) {
        setProcessingStatus('Dubbing completed!');
      } else {
        setProcessingStatus('Processing...');
        setTimeout(() => {
          checkProcessingStatus(videoId);
        }, 5000); // Poll every 5 seconds
      }
    } catch (error) {
      setError('Error checking processing status. Please try again.');
    }
  };

  const handleTitleChange = (e) => {
    setProjectTitle(e.target.value);
  };

  const handleTitleBlur = () => {
    setIsEditingTitle(false);
    if (projectTitle.trim() === '') {
      setProjectTitle('Give your project a name');
    }
  };

  const handleTitleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleTitleBlur();
    }
  };

  const checkConfigChanged = useCallback(() => {
    const currentConfig = {
      transcript: JSON.stringify(transcript),
      selectedLanguage,
      selectedVoice,            
    };

    if (!lastProcessedConfig.current) {
      lastProcessedConfig.current = currentConfig;
      return false;
    }

    const isChanged = JSON.stringify(currentConfig) !== JSON.stringify(lastProcessedConfig.current);
    setIsConfigChanged(isChanged);
  }, [transcript, selectedLanguage, selectedVoice]);

  useEffect(() => {
    checkConfigChanged();
  }, [checkConfigChanged]);

  const handleConfigChange = useCallback(() => {
    checkConfigChanged();
  }, [checkConfigChanged]);

  const handleUpdate = () => {
    setProcessingStatus('Processing...');
    handleVideoDub();
    lastProcessedConfig.current = {
      transcript: JSON.stringify(transcript),
      selectedLanguage,
      selectedVoice,            
    };
    setIsConfigChanged(false);
  };

  const handleViewLibrary = () => {
    openSidePanel();
    navigate('/library');
  };

  const handleHomeClick = () => {
    setShowWarning(true);
  };

  const handleWarningConfirm = () => {
    setShowWarning(false);
    resetState();
  };

  const handleWarningCancel = () => {
    setShowWarning(false);
  };

  useEffect(() => {
    if (publishingStatus === 'completed') {
      setShowLibraryPopup(true);
    }
  }, [publishingStatus]);

  const handleCloseLibraryPopup = () => {
    setShowLibraryPopup(false);
  };

  return (
    <div className="flex h-full flex-col pb-4">
      <div className="pt-0 pb-4 flex items-center justify-between pt-4">
        <motion.button
          className="flex items-center space-x-2 text-indigo-600 hover:text-indigo-800 transition-colors duration-200 group text-base"
          onClick={handleHomeClick}
          whileHover={{ color: '#4f46e5' }}
          transition={{ duration: 0.2 }}
        >
          <Home size={27} className="group-hover:text-indigo-800 transition-colors duration-200" />
          <span className="font-medium">Home</span>
        </motion.button>
        <div className="flex-grow flex items-center justify-center">
          <div className="w-80 min-h-[48px] flex items-center justify-center">
            {isEditingTitle ? (
              <input
                type="text"
                value={projectTitle}
                onChange={handleTitleChange}
                onBlur={handleTitleBlur}
                onKeyDown={handleTitleKeyDown}
                className="text-3xl font-bold bg-transparent border-b-2 border-indigo-300 focus:outline-none focus:border-indigo-500 text-indigo-800 text-center w-full"
                autoFocus
              />
            ) : (
              <div className="flex items-center justify-center">
                <h1
                  className="text-2xl font-bold cursor-pointer text-indigo-800 text-center cursor-default hover:cursor-text truncate max-w-xs"
                  onClick={() => setIsEditingTitle(true)}
                >
                  {projectTitle}
                </h1>
                {!isEditingTitle && (
                  <button
                    className="ml-2 text-indigo-500 hover:text-indigo-700 flex-shrink-0"
                    onClick={() => setIsEditingTitle(true)}
                  >
                    ✏️
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="ml-4">
          <div className="flex items-center">
            <PublishButton
              user={user}
              uploadedVideoId={uploadedVideoId}
              selectedLanguage={selectedLanguage}
              selectedVoice={selectedVoice}
              transcript={transcript} 
              setProjectTitle={setProjectTitle}           
              projectTitle={projectTitle}
              musicVolume={musicVolume}
              musicID={selectedMusicId}
              backgroundImageId={backgroundImageId}
              setDubbedVideoId={setDubbedVideoId}
              publishingStatus={publishingStatus}
              setPublishingStatus={setPublishingStatus}
            />
          </div>
        </div>
      </div>
      
      <div className="w-full h-px bg-gray-300 mb-6"></div>
      
      <div className="flex flex-grow h-full overflow-visible mb-0">
        <div className="w-2/3 mr-4 overflow-visible flex flex-col">
          <div className="flex-grow relative overflow-hidden bg-white rounded-2xl shadow-lg" style={{ height: 'calc(100vh - 150px)' }}>
            <div className="flex justify-between items-center p-3 border-b border-gray-200 h-[64px]">
              <div className="w-12"></div> {/* Spacer to balance the right button in Edit Panel */}
              <h3 className="text-2xl font-semibold text-center">Polished Video</h3>
              <div className="w-12"></div> {/* Spacer to balance the right button in Edit Panel */}
            </div>
            <div className="w-full h-[calc(100%-64px)] relative flex items-center justify-center">
              <div className="w-full h-full px-2 py-2 flex items-center justify-center">
                <div className="aspect-video w-full h-auto max-h-[calc(100%-4px)] rounded-lg overflow-hidden">
                  {processingStatus === 'Processing...' ? (
                    <div className="absolute inset-0 flex flex-col items-center justify-center bg-gradient-to-br from-indigo-100 to-purple-100 rounded-2xl">
                      <motion.div 
                        className="text-6xl mb-4"
                        animate={{ rotate: 360 }}
                        transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
                      >
                        <Wand2 className="text-indigo-600" />
                      </motion.div>
                      <p className="text-2xl font-semibold text-indigo-700 mb-2">Processing...</p>                              
                      <p className="text-xl font-semibold text-indigo-700 mb-2">(takes upto 2 minutes for large videos)</p>                              
                      <div className="mt-6">
                        {[0, 1, 2].map((i) => (
                          <motion.div 
                            key={i}
                            className="inline-block w-3 h-3 mx-1 bg-indigo-500 rounded-full"
                            animate={{ 
                              y: [0, -10, 0],
                              opacity: [0.5, 1, 0.5],
                              scale: [1, 1.2, 1]
                            }}
                            transition={{ 
                              duration: 1.5, 
                              repeat: Infinity, 
                              delay: i * 0.2 
                            }}
                          />
                        ))}
                      </div>
                    </div>
                  ) : (
                    dubbedVideoId ? (
                      <div className="absolute inset-0">
                        <OverlayVideoPlayer 
                          key={dubbedVideoId}
                          user={user} 
                          videoId={dubbedVideoId} 
                          backgroundImageUrl={backgroundImageId ? imageCache.get(backgroundImageId) : null}
                          className={`w-full h-full object-contain ${isConfigChanged ? 'filter blur-sm' : ''}`}
                          isPaused={isConfigChanged}
                          selectedMusicId={selectedMusicId}
                          musicVolumePercentage={musicVolume * 100}
                        />
                        <AnimatePresence>
                          {isConfigChanged && (
                            <motion.div
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              exit={{ opacity: 0 }}
                              className="absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-60"
                            >
                              <motion.button
                                className="flex items-center space-x-2 bg-gradient-to-r from-purple-600 to-indigo-600 text-white font-semibold py-3 px-6 rounded-lg shadow-md transition-colors duration-300 hover:shadow-lg mb-4"
                                whileHover={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)" }}
                                onClick={handleUpdate}
                              >
                                <RefreshCw size={24} className="mr-2" />
                                <span className="text-lg">Update Preview</span>
                              </motion.button>

                              <motion.p
                                initial={{ opacity: 0, y: 10 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ delay: 0.2 }}
                                className="text-white text-sm font-medium max-w-xs text-center"
                              >
                                Changes detected. Update to see them in action.
                              </motion.p>

                              <motion.button
                                className="flex items-center space-x-2 bg-gradient-to-r from-red-600 to-pink-600 text-white font-semibold py-3 px-6 rounded-lg shadow-md transition-colors duration-300 hover:shadow-lg mt-4 mb-4"
                                whileHover={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)" }}
                                onClick={() => {
                                  setTranscript(JSON.parse(lastProcessedConfig.current.transcript));
                                  setSelectedLanguage(lastProcessedConfig.current.selectedLanguage);
                                  setSelectedVoice(lastProcessedConfig.current.selectedVoice);
                                  // setProjectTitle(lastProcessedConfig.current.projectTitle);
                                  setSelectedMusicId(lastProcessedConfig.current.selectedMusicId);
                                  setMusicVolume(lastProcessedConfig.current.musicVolume);
                                  setIsConfigChanged(false);
                                }}
                              >
                                <RotateCcw size={20} className="mr-2" />
                                <span className="text-sm">Revert</span>
                              </motion.button>
                            </motion.div>
                          )}
                        </AnimatePresence>
                      </div>
                    ) : (
                      <div className="absolute inset-0 flex items-center justify-center bg-gray-100 text-gray-500 rounded-2xl">
                        Video will be displayed here once processing is complete
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-1/3 bg-white rounded-2xl shadow-lg overflow-visible flex flex-col" style={{ height: 'calc(100vh - 150px)' }}>
          <div className="flex justify-between items-center p-3 border-b border-gray-200 h-[64px]">
            <h3 className="text-2xl font-semibold">Edit Panel</h3>
            <div className="flex items-center">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  window.open('https://loomos.co/tutorial', '_blank');
                }}
                className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors font-semibold"
              >
                See Tutorial
              </button>
            </div>
          </div>
          <div className="flex flex-col flex-grow overflow-hidden">
            <div className="flex border-b border-gray-200">
              <TabButton icon={Edit} label="Transcript" isActive={selectedTab === 'transcript'} onClick={() => setSelectedTab('transcript')} />
              <TabButton icon={Mic} label="Voices" isActive={selectedTab === 'voices'} onClick={() => setSelectedTab('voices')} isNew={true} />
              <TabButton icon={Image} label="Backgrounds" isActive={selectedTab === 'backgrounds'} onClick={() => setSelectedTab('backgrounds')} />
              <TabButton icon={Music} label="Music" isActive={selectedTab === 'music'} onClick={() => setSelectedTab('music')} />
            </div>
            <div className="flex-grow overflow-auto" style={{ height: 'calc(100% - 56px)', padding: '12px' }}>
              {selectedTab === 'transcript' && (
                <TranscriptTab 
                  user={user}
                  transcript={transcript}
                  setTranscript={setTranscript}
                  isTranslating={isTranslating}
                  setIsTranslating={setIsTranslating}
                  selectedLanguage={selectedLanguage}
                  setSelectedLanguage={setSelectedLanguage}
                  languages={languages}
                  uploadedVideoId={uploadedVideoId}
                  onConfigChange={handleConfigChange}
                  originalTranscript={originalTranscript}                  
                />
              )}
              {selectedTab === 'voices' && (
                <VoicesTab 
                  voices={voices}
                  selectedVoice={selectedVoice}
                  setSelectedVoice={setSelectedVoice}
                  handleVoicePlay={handleVoicePlay}
                  onConfigChange={handleConfigChange}
                />
              )}
              {selectedTab === 'backgrounds' && (
                <BackgroundsTab 
                  user={user}
                  setBackgroundImage={setBackgroundImageId}
                  backgroundImage={backgroundImageId}
                  images={images}
                  setImages={setImages}
                  fetchImages={fetchImages}
                  onConfigChange={handleConfigChange}
                  imageCache={imageCache}
                  imagesLoading={imagesLoading}
                />
              )}
              {selectedTab === 'music' && (
                <MusicTab
                  musicList={musicList}
                  setMusicID={setSelectedMusicId}
                  setMusicVolume={setMusicVolume}
                  idToken={user.getIdToken()}
                  initialVolume={musicVolume}
                  initialMusicId={selectedMusicId}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <AnimatePresence>
        {showLibraryPopup && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50"
            style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0 }}
          >
            <motion.div
              initial={{ scale: 0.9, opacity: 0, y: 20 }}
              animate={{ scale: 1, opacity: 1, y: 0 }}
              exit={{ scale: 0.9, opacity: 0, y: 20 }}
              transition={{ type: "spring", damping: 15, stiffness: 300 }}
              className="bg-white rounded-xl shadow-2xl p-8 max-w-md w-full mx-4 relative overflow-hidden"
              style={{ width: '450px', maxHeight: '90vh' }}
            >
              <div className="absolute top-0 left-0 w-full h-2 bg-gradient-to-r from-indigo-500 to-purple-500" />
              <button
                onClick={handleCloseLibraryPopup}
                className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 transition-colors duration-200"
              >
                <X size={24} />
              </button>
              <div className="flex items-center mb-6">
                <div className="bg-green-100 rounded-full p-2 mr-4">
                  <Check className="text-green-500" size={28} />
                </div>
                <h3 className="text-2xl font-semibold text-gray-800">
                  Video Published!
                </h3>
              </div>
              <p className="text-gray-600 mb-6">
                Your video has been successfully published and is now available in your library.
                <br />                
                <strong>Title: {projectTitle}</strong>
              </p>
              <motion.button
                whileHover={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)" }}
                transition={{ duration: 0.2 }}
                className="w-full bg-gradient-to-r from-indigo-600 to-purple-600 text-white px-6 py-3 rounded-lg text-lg font-medium flex items-center justify-center shadow-md hover:shadow-lg transition-colors duration-200"
                onClick={handleViewLibrary}
              >
                <Library size={20} className="mr-2" />
                View in Library
              </motion.button>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {showWarning && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
            style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0 }}
          >
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              className="bg-white rounded-lg p-6 max-w-md"
              style={{ width: '400px' }}
            >
              <div className="flex items-center mb-4 text-yellow-500">
                <AlertTriangle size={24} className="mr-2" />
                <h3 className="text-lg font-semibold">Warning</h3>
              </div>
              <p className="mb-4">If you haven't saved your video, it will be lost. Save it before leaving. Do you want to proceed?</p>
              <div className="flex justify-end space-x-4">
                <button
                  onClick={handleWarningCancel}
                  className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition-colors"
                >
                  Cancel
                </button>
                <button
                  onClick={handleWarningConfirm}
                  className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
                >
                  Proceed
                </button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {publishingStatus === 'processing' && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50"
            style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0 }}
          >
            <motion.div
              initial={{ scale: 0.9, opacity: 0, y: 20 }}
              animate={{ scale: 1, opacity: 1, y: 0 }}
              exit={{ scale: 0.9, opacity: 0, y: 20 }}
              transition={{ type: "spring", damping: 15, stiffness: 300 }}
              className="bg-white rounded-xl shadow-2xl p-8 max-w-md w-full mx-4 relative overflow-hidden"
              style={{ width: '450px' }}
            >
              <div className="absolute top-0 left-0 w-full h-2 bg-gradient-to-r from-indigo-500 to-purple-500" />
              <div className="flex items-center mb-6">
                <div className="bg-blue-100 rounded-full p-2 mr-4">
                  <RefreshCw className="text-blue-500 animate-spin" size={28} />
                </div>
                <h3 className="text-2xl font-semibold text-gray-800">
                  Saving Your Video
                </h3>
              </div>
              <p className="text-gray-600 mb-6">
                Please wait while we save your video. This process can take up to 2 minutes.
                <br />
                <strong>Title: {projectTitle || 'Untitled'}</strong>
              </p>
              <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                <div className="bg-blue-600 h-2.5 rounded-full w-full animate-pulse"></div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
          <span className="block sm:inline">{error}</span>
          <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
            <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" onClick={() => setError(null)}>
              <title>Close</title>
              <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/>
            </svg>
          </span>
        </div>
      )}
    </div>
  );
}

const TabButton = ({ icon: Icon, label, isActive, onClick, isNew }) => (
  <motion.button
    className={`flex items-center justify-center space-x-2 px-4 py-3 text-sm font-medium relative min-w-[100px] h-[48px] ${
      isActive ? 'text-indigo-600 border-b-2 border-indigo-600' : 'text-gray-500 hover:text-gray-700 border-b-2 border-transparent'
    }`}
    whileHover={{ scale: 1.02 }}
    whileTap={{ scale: 0.98 }}
    onClick={onClick}
  >
    <Icon size={18} />
    <span>{label}</span>
    {isNew && (
      <span className="absolute -top-1 -right-1 bg-red-500 text-white text-[8px] px-1 py-0.5 rounded-full transform origin-top-left">
        NEW
      </span>
    )}
  </motion.button>
);

export default ProcessingStatus;

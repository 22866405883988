import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import config from '../config';
import { motion, AnimatePresence } from 'framer-motion';
import { Search, PlayCircle, Share2, Check, Download, Code, X, Clock } from 'lucide-react';
import useTawkTo from '../hooks/useTawkTo';

const LibraryView = ({ user, openSidePanel }) => {
  useTawkTo();
  
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredVideos, setFilteredVideos] = useState([]);
  const [processedVideos, setProcessedVideos] = useState([]);
  const [videoUrls, setVideoUrls] = useState({}); // Store video URLs for all videos
  const [selectedVideoId, setSelectedVideoId] = useState(null);
  const [copiedVideoId, setCopiedVideoId] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [downloadingVideoId, setDownloadingVideoId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [visibleVideoIds, setVisibleVideoIds] = useState(new Set());
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const observerRef = useRef(null);
  const itemsPerPage = 8; // Number of videos per page

  // Setup intersection observer for lazy loading
  useEffect(() => {
    observerRef.current = new IntersectionObserver((entries) => {
      const visibleIds = new Set(visibleVideoIds);
      
      entries.forEach(entry => {
        const videoId = entry.target.dataset.videoId;
        if (entry.isIntersecting) {
          visibleIds.add(videoId);
          if (videoId && !videoUrls[videoId]) {
            preloadVideoUrl(videoId);
          }
        } else {
          visibleIds.delete(videoId);
        }
      });
      
      setVisibleVideoIds(visibleIds);
    }, {
      root: null,
      rootMargin: '100px',
      threshold: 0.1
    });
    
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [videoUrls, visibleVideoIds]);

  // Function to load videos with pagination
  const fetchProcessedVideos = async (pageNum = 1, append = false) => {
    if (!user) return;
    
    setIsLoading(true);
    try {
      const idToken = await user.getIdToken();
      const response = await axios.get(`${config.API_BASE_URL}/processed-videos/${user.uid}`, {
        headers: { 'Authorization': `Bearer ${idToken}` },
        params: {
          page: pageNum,
          limit: itemsPerPage
        }
      });
      
      if (response.data && Array.isArray(response.data.videos)) {
        const newVideos = response.data.videos;
        setHasMore(newVideos.length === itemsPerPage);
        
        if (append) {
          setProcessedVideos(prev => [...prev, ...newVideos]);
        } else {
          setProcessedVideos(newVideos);
        }
        
        // Don't preload all videos immediately, they'll be loaded when visible
      } else {
        console.error('Unexpected response format:', response.data);
        setProcessedVideos(append ? processedVideos : []);
        setHasMore(false);
      }
    } catch (error) {
      console.error('Error fetching processed videos:', error);
      setProcessedVideos(append ? processedVideos : []);
      setHasMore(false);
    } finally {
      setIsLoading(false);
    }
  };

  // Initial load
  useEffect(() => {
    if (user) {
      fetchProcessedVideos(1, false);
    }
  }, [user]);

  // Register elements with intersection observer
  useEffect(() => {
    const videoElements = document.querySelectorAll('.video-thumbnail');
    videoElements.forEach(element => {
      if (observerRef.current) {
        observerRef.current.observe(element);
      }
    });
    
    return () => {
      videoElements.forEach(element => {
        if (observerRef.current) {
          observerRef.current.unobserve(element);
        }
      });
    };
  }, [filteredVideos]);

  // Load more videos when scrolling to bottom
  const loadMoreVideos = () => {
    if (!isLoading && hasMore) {
      const nextPage = page + 1;
      setPage(nextPage);
      fetchProcessedVideos(nextPage, true);
    }
  };

  useEffect(() => {
    setFilteredVideos(
      processedVideos.filter(video =>
        video.title.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, processedVideos]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  // Method to preload video URLs - only called when a video becomes visible
  const preloadVideoUrl = async (videoId) => {
    // Skip if URL already exists
    if (videoUrls[videoId]) return;
    
    try {
      const idToken = await user.getIdToken();
      // Use a small timeout to prevent too many simultaneous requests
      setTimeout(async () => {
        const videoUrl = `${config.API_BASE_URL}/stream-video/${videoId}?token=${encodeURIComponent(idToken)}`;
        setVideoUrls(prev => ({ ...prev, [videoId]: videoUrl }));
      }, Math.random() * 300); // Small random delay to stagger requests
    } catch (error) {
      console.error('Error preloading video URL:', error);
    }
  };

  // Updated to use pre-loaded URLs and be more like SharedVideoPage
  const handlePlayVideo = (videoId) => {
    setSelectedVideoId(videoId);
    setIsPlaying(true);
    
    // Use setTimeout to give the modal time to render before trying to play
    setTimeout(() => {
      if (videoRef.current) {
        videoRef.current.play().catch(error => {
          console.error('Error trying to play the video:', error);
        });
      }
    }, 100);
  };

  const handleVideoError = (error) => {
    console.error('Video playback error:', error);
    setSelectedVideoId(null);
    setIsPlaying(false);
  };

  const handleCloseModal = () => {
    setSelectedVideoId(null);
    setIsPlaying(false);
  };

  const handleShareVideo = (videoId) => {
    const shareUrl = `${window.location.origin}/shared/${videoId}`;
    navigator.clipboard.writeText(shareUrl).then(() => {
      setCopiedVideoId(videoId);
      setShowNotification(true);
      setTimeout(() => {
        setCopiedVideoId(null);
        setShowNotification(false);
      }, 3000); // Hide after 3 seconds
    }).catch((err) => {
      console.error('Failed to copy share link: ', err);
    });
  };

  const handleDownload = async (videoId, title) => {
    try {
      setDownloadingVideoId(videoId); // Set downloading state
      const idToken = await user.getIdToken();
      const response = await axios.get(`${config.API_BASE_URL}/stream-video/${videoId}`, {
        headers: { 'Authorization': `Bearer ${idToken}` },
        responseType: 'blob' // Keep responseType as blob only for downloads
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${title}.mp4`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Error downloading video:', error);
    } finally {
      setDownloadingVideoId(null); // Reset downloading state
    }
  };

  const formatDuration = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  useEffect(() => {
    if (openSidePanel && typeof openSidePanel === 'function') {
      openSidePanel();
    }
  }, [openSidePanel]);

  return (
    <div className="max-w-6xl mx-auto space-y-8 p-6">
      <h2 className="text-3xl font-bold mb-6 text-[#3C82F6]">My Library</h2>
      
      <div className="relative">
        <input
          type="text"
          placeholder="Search videos..."
          value={searchTerm}
          onChange={handleSearch}
          className="w-full p-4 pl-12 pr-4 text-gray-700 bg-white border-2 border-gray-200 rounded-full focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent transition-all duration-300"
        />
        <Search className="absolute left-4 top-4 text-gray-400" size={20} />
      </div>

      <AnimatePresence>
        <motion.div 
          className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.5, ease: "easeInOut" }}
        >
          {filteredVideos.map((video) => (
            <motion.div
              key={video.id}
              className="bg-white rounded-xl shadow-lg overflow-hidden transform transition-all duration-300 hover:shadow-2xl"
              whileHover={{ scale: 1.03, y: -5 }}
              whileTap={{ scale: 0.98 }}
            >
              <div className="relative aspect-video">
                <div 
                  className="video-thumbnail w-full h-full bg-gray-100"
                  data-video-id={video.video_id}
                >
                  {videoUrls[video.video_id] && (
                    <video
                      src={videoUrls[video.video_id]}
                      className="w-full h-full object-cover"
                      preload="metadata"
                      muted
                      playsInline
                      disablePictureInPicture
                    />
                  )}
                </div>
                <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent opacity-0 hover:opacity-100 transition-opacity duration-300">
                  <button
                    className="absolute inset-0 flex items-center justify-center"
                    onClick={() => handlePlayVideo(video.video_id)}
                  >
                    <PlayCircle className="text-white" size={64} />
                  </button>
                </div>
                <div className="absolute bottom-2 right-2 bg-black bg-opacity-80 text-white px-1.5 py-0.5 rounded-md text-xs flex items-center">
                  <Clock size={10} className="mr-1" />
                  {formatDuration(video.duration)}
                </div>
              </div>
              <div className="p-3 flex flex-col space-y-2">
                <h3 className="text-sm font-medium text-gray-800 break-words">
                  {video.title}
                </h3>
                <div className="flex items-center space-x-2">
                  <motion.button
                    className={`p-1.5 rounded-full transition-all duration-300 ${
                      copiedVideoId === video.video_id
                        ? 'bg-green-500 text-white'
                        : 'bg-gray-100 text-indigo-600 hover:bg-indigo-100'
                    }`}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    onClick={() => handleShareVideo(video.video_id)}
                  >
                    {copiedVideoId === video.video_id ? (
                      <Check size={16} />
                    ) : (
                      <Share2 size={16} />
                    )}
                  </motion.button>
                  <motion.button
                    className="p-1.5 rounded-full bg-gray-100 text-indigo-600 hover:bg-indigo-100 transition-all duration-300"
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    onClick={() => handleDownload(video.video_id, video.title)}
                    disabled={downloadingVideoId === video.video_id}
                  >
                    {downloadingVideoId === video.video_id ? (
                      <div className="animate-spin">
                        <Clock size={16} />
                      </div>
                    ) : (
                      <Download size={16} />
                    )}
                  </motion.button>
                </div>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </AnimatePresence>

      {isLoading && (
        <div className="text-center py-4">
          <div className="inline-block animate-spin">
            <Clock className="text-indigo-500" size={24} />
          </div>
          <p className="mt-2 text-gray-600">Loading videos...</p>
        </div>
      )}

      {!isLoading && hasMore && filteredVideos.length > 0 && (
        <div className="flex justify-center mt-8">
          <button
            onClick={loadMoreVideos}
            className="px-6 py-2 bg-indigo-100 text-indigo-600 rounded-full hover:bg-indigo-200 transition-colors"
          >
            Load more videos
          </button>
        </div>
      )}

      {filteredVideos.length === 0 && !isLoading && (
        <motion.p 
          className="text-center text-xl text-gray-600 mt-12"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          No videos found. Start creating your first project!
        </motion.p>
      )}

      <AnimatePresence>
        {selectedVideoId && (
          <motion.div 
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div 
              className="relative bg-white rounded-2xl shadow-2xl w-full max-w-4xl overflow-hidden"
              initial={{ scale: 0.9, y: 20 }}
              animate={{ scale: 1, y: 0 }}
              exit={{ scale: 0.9, y: 20 }}
              transition={{ type: "spring", damping: 15 }}
            >
              <button
                className="absolute top-2 right-2 z-10 text-white hover:text-gray-200 transition-colors duration-200"
                onClick={handleCloseModal}
              >
                <X size={32} />
              </button>
              
              <div className="relative" style={{ paddingTop: '56.25%' }}>
                <video
                  ref={videoRef}
                  src={videoUrls[selectedVideoId]}
                  className="absolute inset-0 w-full h-full object-contain bg-black"
                  controls
                  playsInline
                  preload="auto"
                  onError={handleVideoError}
                />
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {showNotification && (
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.3 }}
            className="fixed top-4 right-4 left-4 md:left-auto md:w-auto bg-white rounded-lg shadow-lg p-4 flex items-center justify-between z-50"
          >
            <div className="flex items-center space-x-2">
              <Check className="text-green-500 flex-shrink-0" size={20} />
              <span className="text-gray-800 font-medium">
                {copiedVideoId ? "Link copied to clipboard!" : "Embed code copied to clipboard!"}
              </span>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {downloadingVideoId && (
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.3 }}
            className="fixed top-4 right-4 left-4 md:left-auto md:w-auto bg-white rounded-lg shadow-lg p-4 flex items-center justify-between z-50"
          >
            <div className="flex items-center space-x-2">
              <div className="animate-spin">
                <Clock className="text-indigo-500 flex-shrink-0" size={20} />
              </div>
              <span className="text-gray-800 font-medium">
                Preparing download, please wait...
              </span>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default LibraryView;
